import React, { useState, FormEvent } from 'react'
import {Button, Input, Form, Header, Message} from "semantic-ui-react";
import {post} from "../components/FetchApi";

const RequestPwdResetForm = () => {
	const [ user, setUser ] = useState({username: '', password: ''});
	const [ error, setError ] = useState<string | undefined>(undefined);
	const [ success, setSuccess ] = useState<string | undefined>(undefined);

	const handleInputChange = (event:FormEvent<HTMLInputElement>) => {
		const { name, value } = (event.target as HTMLInputElement);
		setUser({ ...user, [name]: value });
	};

	const handleResponse = async (response:Response) => {
		if (response.status === 200) {
			setError(undefined)
			setSuccess('A password reset link has been sent to the email address given, if the account exists.');
		} else {
			setSuccess(undefined)
			setError('Something went wrong. Please contact support!');
		}
	};

	const handleSubmit = (event:FormEvent) => {
		event.preventDefault();
		if (user.username === '') {
			setError('Email address required!')
			return;
		}

		post(process.env.REACT_APP_API_URL + "/create_reset_token", user, handleResponse);
	};

	return (
		<>
			<Header as='h2' textAlign="center">Request password reset</Header>
			<Form size="large" onSubmit={event => handleSubmit(event)} error={!!error}>
				<Form.Field
					control={Input}
					label='Email'
					name="username"
					value={user.username}
					onChange={handleInputChange}
					required
				/>
				<Button primary content="Send password reset link" />
				<Message
      		error
      		content={error}
					visible={!!error} />
				<Message
					success
					content={success}
				  visible={!!success} />
			</Form>
		</>
	)
};

export default RequestPwdResetForm
