import React from 'react'
import {Route} from 'react-router-dom'
import {Grid, Segment} from "semantic-ui-react";
import ResetPwdForm from "../forms/ResetPwdForm";
import { useSearchParams } from "react-router-dom";

const ResetPwd = () => {
  let [searchParams] = useSearchParams();

  return (
    <Route>
      <Grid centered columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <ResetPwdForm queryParams={searchParams} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Route>
  )
};

export default ResetPwd
