import React, { useState, FormEvent } from 'react'
import {Button, Input, Form, Header, Message} from "semantic-ui-react";
import {post} from "../components/FetchApi";

const SignupForm = () => {
	const [ user, setUser ] = useState({username: '', password: '', signupSecret: ''});
	const [ isError, setError ] = useState(false);
	const [ isSuccess, setSuccess ] = useState(false);

	const handleInputChange = (event:FormEvent<HTMLInputElement>) => {
		const { name, value } = (event.target as HTMLInputElement);
		setUser({ ...user, [name]: value });
	};

	const handleResponse = (response:Response) => {
		if (response.status === 200) {
			setError(false);
			setSuccess(true)
		} else {
			setError(true);
		}
	};

	const handleSubmit = (event:FormEvent) => {
		event.preventDefault();
		if (user.username === '' || user.password === '' || user.signupSecret === '') return;

		post(process.env.REACT_APP_API_URL + "/perform_signup", user, handleResponse);
	};

	return (
		<>
			<Header as='h2' textAlign="center">Signup</Header>
			<Form size="large" onSubmit={event => {
				handleSubmit(event);
			}} error={isError} success={isSuccess}>
				<Form.Field
					control={Input}
					label='Email'
					name="username"
					value={user.username}
					onChange={handleInputChange}
					required
				/>
				<Form.Field
					control={Input}
          type='password'
					label='Password'
					name="password"
					value={user.password}
					onChange={handleInputChange}
					required
				/>
				<Form.Field
					control={Input}
          type='password'
					label='Secret key'
					name="signupSecret"
					value={user.signupSecret}
					onChange={handleInputChange}
					required
				/>
				<Button primary icon='sign in alternate' content="Signup" />
				<Message error
      		content='Signup failed, please try again.' />
				<Message success>
      		Successfully signed up, you may now <a href="/login"><strong>Login</strong></a>.
				</Message>
			</Form>
		</>
	)
};

export default SignupForm
