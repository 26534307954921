import React, {useState, FormEvent, useEffect} from 'react'
import {Button, Input, Form, Header, Message} from "semantic-ui-react";
import {post} from "../components/FetchApi";
import qs from 'query-string'
import {ParsedUrlQuery} from "querystring";

interface Props {
	queryParams: URLSearchParams
}

type ResetQueryParams = {
	username: string
	token: string
}

const ResetPwdForm = ({queryParams}: Props) => {
	const [ user, setUser ] = useState({username: '', password: '', token: ''});
	const [ isError, setError ] = useState(false);
	const [ isSuccess, setSuccess ] = useState(false);

	useEffect(() => {
		const query = queryParams
		setUser({ ...user, username: query.get('username') || '', token: query.get('token') || '' });
	}, [])

	const handleInputChange = (event:FormEvent<HTMLInputElement>) => {
		const { name, value } = (event.target as HTMLInputElement);
		setUser({ ...user, [name]: value });
	};

	const handleResponse = (response:Response) => {
		if (response.status === 200) {
			setError(false);
			setSuccess(true)
		} else {
			setError(true);
		}
	};

	const handleSubmit = (event:FormEvent) => {
		event.preventDefault();
		if (user.username === '' || user.password === '' || user.token === '') return;

		post(process.env.REACT_APP_API_URL + "/perform_reset_pwd", user, handleResponse);
	};

	return (
		<>
			<Header as='h2' textAlign="center">Password reset</Header>
			<Form size="large" onSubmit={event => {
				handleSubmit(event);
			}} error={isError} success={isSuccess}>
				<Form.Field
					control={Input}
          type='password'
					label='New password'
					name="password"
					value={user.password}
					onChange={handleInputChange}
					required
				/>
				<Button primary icon='sign in alternate' content="Reset" />
				<Message error
      		content='Reset failed, the link might have expired or is invalid.' />
				<Message success>
      		Password has been reset, you may now <a href="/login"><strong>Login</strong></a>.
				</Message>
			</Form>
		</>
	)
};

export default ResetPwdForm
