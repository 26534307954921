const parserNames = [
  {text: 'Tori.fi', value: 'TORI'},
  {text: 'Hintaopas.fi', value: 'HINTAOPAS'},
  {text: 'Blocket.se', value: 'BLOCKET'},
  {text: 'mResell.fi', value: 'MRESELL'},
  {text: 'Huutokaupat.com', value: 'HUUTOKAUPAT'},
  {text: 'Honk.fi', value: 'HONK'},
  {text: 'Nettiauto.com', value: 'NETTIAUTO'},
  {text: 'Oikotie.fi Asunnot', value: 'OIKOTIE ASUNNOT'},
  {text: 'Verkkokauppa.com', value: 'VERKKOKAUPPA.COM'},
  {text: 'Gigantti', value: 'GIGANTTI'},
  {text: 'Muusikoiden.net', value: 'MUUSIKOIDEN.NET'},
  {text: 'AutoScout24', value: 'AUTOSCOUT24'},
  {text: 'Auto24.ee', value: 'AUTO24.EE'},
  {text: 'Osta.ee', value: 'OSTA.EE'},
  {text: 'ALD Carmarket', value: 'ALDCARMARKET'},
  {text: 'Lääkehaku', value: 'LAAKEHAKU'},
];

const frequencyNames = [
  {text: 'Every 5 mins', value: 'DAILY_5_MINS'},
  {text: 'Every hour', value: 'HOURLY'},
  {text: 'Every 2 hours', value: 'DAILY_2_HOURS'},
  {text: 'Every 4 hours', value: 'DAILY_4_HOURS'},
  {text: 'Every 8 hours', value: 'DAILY_8_HOURS'},
  {text: 'Every 12 hours', value: 'DAILY_12_HOURS'},
  {text: 'Every day', value: 'DAILY'}
];

const getFrequencyName = (frequency:string) => {
  const foundFreq = frequencyNames.find(f => f.value === frequency);
  return foundFreq ? foundFreq.text : ''
};

const canViewDetails = (user: User, searchTask: SearchTask): boolean => {
  if (!user || !searchTask || !searchTask.user) {
    return false;
  }
  return searchTask.user.username === user.username || searchTask.allowAdminToViewDetails;
};

export default {
  parserNames,
  frequencyNames,
  getFrequencyName,
  canViewDetails
}
