import React, { useState, useEffect } from 'react'
import { Button, Modal, Item } from "semantic-ui-react";
import Moment from 'react-moment';
import {fetchUrl} from "./FetchApi";

interface Props {
	currentSearchTask: SearchTask
	setModalOpen: Function
	isModalOpen: boolean
	loading: boolean
	setLoading: Function
	title: string
}

const Postings = (props: Props) => {
	const [postings, setPostings] = useState<Array<Posting>>([]);

	useEffect(
    () => {
			if (props.isModalOpen && props.currentSearchTask && props.currentSearchTask.id) {
				fetchPostings(props.currentSearchTask.id);	
			}
    },[props]
	);

	const reducePostings = (postings:Posting[]) => {
    if (postings) {
      props.setLoading(false);
      setPostings(postings);
    } else {
      props.setLoading(false);
      setPostings([]);
    }
  };

	const fetchPostings = (searchTaskId:number) => {
		fetchUrl(process.env.REACT_APP_API_URL + "/api/posting/" + searchTaskId, reducePostings);
	};

	const closeModal = () => {
		props.setModalOpen(false);
	};

	return (
		<Modal
			open={props.isModalOpen}
			closeOnEscape={true}
			closeOnDimmerClick={true}
			onClose={closeModal}
		>
			<Modal.Header as='h2' className={"ui"}>{props.currentSearchTask.description}</Modal.Header>
			<Modal.Content scrolling>
				<Modal.Description>
					<>
						{postings && postings.length > 0 ? (
							<Item.Group divided>
								{postings.map(posting => (
										<Item>
											<Item.Content>
												<Item.Header as='a' href={posting.link} target="_blank">{posting.originalTitle}</Item.Header>
												<Item.Meta>Description</Item.Meta>
												<Item.Description>
													{posting.originalDescription ? posting.originalDescription : 'No description available'}
												</Item.Description>
												<Item.Meta>Price</Item.Meta>
												<Item.Description>
													{posting.price}
												</Item.Description>
												<Item.Extra>First found on:
													{posting.wasFoundOn &&
														<Moment date={posting.wasFoundOn} interval={0} format=' D.M.YYYY HH:mm'/>
													}
												</Item.Extra>
												<Item.Extra>Last updated:
													{posting.wasUpdatedOn &&
														<Moment date={posting.wasUpdatedOn} interval={0} format=' D.M.YYYY HH:mm'/>
													}
												</Item.Extra>
											</Item.Content>
										</Item>
								))}
							</Item.Group>
						) :
							<>No postings available</>
						}
					</>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => closeModal()}>Close</Button>
			</Modal.Actions>
		</Modal>
	)
};

export default Postings
