import React, {useState, useEffect} from 'react'

import {Route} from 'react-router-dom'
import {Grid, Header, Segment, Button, Popup} from "semantic-ui-react";
import useLocalStorage from 'react-use-localstorage';
import {fetchUrl, syncFetchUrl, postUrl, putUrl, deleteUrl, refreshToken} from "./FetchApi";
import SearchTaskForm, {initialFormState} from '../forms/SearchTaskForm'
import SearchTaskTable from '../tables/SearchTaskTable'
import Postings from './Postings'
import useInterval from './UseInterval'

const SearchTasks = () => {
  const [isShowAllUsers, setShowAllUsers] = useLocalStorage('showAllUsers', "false");
  const [isShowOnlyActive, setShowOnlyActive] = useLocalStorage('showOnlyActive', "false");
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTasks, setSearchTasks] = useState<Array<SearchTask>>([]);
  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isPostingsModalOpen, setPostingsModalOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isAdmin, setAdmin] = useState(false);

  const [currentSearchTask, setCurrentSearchTask] = useState<SearchTask>(initialFormState);

  const reduceSearchTasks = (searchTasks:SearchTask[]) => {
    if (searchTasks) {
      setLoading(false);
      setSearchTasks(searchTasks);
    } else {
      setLoading(false);
      setSearchTasks([]);
    }
  };

  const reduceUpdatedSearchTask = (updatedSearchTask:SearchTask) => {
    if (updatedSearchTask) {
      setLoading(false);
      setSearchTasks(searchTasks.map(searchTask => (searchTask.id === updatedSearchTask.id ? updatedSearchTask : searchTask)));
    }
  };

  const reduceDeletedSearchTask = (deletedSearchTask:SearchTask) => {
    if (deletedSearchTask) {
      setLoading(false);
      setSearchTasks(searchTasks.filter(searchTask => searchTask.id !== deletedSearchTask.id));
    }
  };

  const reduceInsertedSearchTask = (searchTask:SearchTask) => {
    if (searchTask) {
      setLoading(false);
      setSearchTasks([...searchTasks, searchTask]);
    }
  };

  const reduceUser = (user:User) => {
    if (user) {
      setUser(user);
      setAdmin(user.role === "ROLE_ADMIN");
    }
    return user;
  };

  const fetchSearchTasks = (showAllUsers:boolean, showOnlyActive:boolean) => {
    let params = "";
    if (showOnlyActive) {
      params = "?showOnlyActive=1"
    }
    if (showAllUsers) {
      fetchUrl(process.env.REACT_APP_API_URL + "/api/allSearchTasks" + params, reduceSearchTasks);
    } else {
      fetchUrl(process.env.REACT_APP_API_URL + "/api/searchTasks" + params, reduceSearchTasks);
    }
  };

  useEffect(() => {
    if (!user || !user.role) {
      syncFetchUrl(process.env.REACT_APP_API_URL + "/user/me")
        .then((user:User) => {
          reduceUser(user);
          fetchSearchTasks((user && user.role === "ROLE_ADMIN" && isShowAllUsers === "true"), isShowOnlyActive === "true")
        });
    } else {
      fetchSearchTasks(isAdmin && isShowAllUsers === "true", isShowOnlyActive === "true");
    }

  }, [isShowAllUsers, isShowOnlyActive]);

  useInterval(() => {
    refreshToken(process.env.REACT_APP_API_URL + "/user/refresh");
  }, 60000);

  // CRUD operations
  const insertSearchTask = (searchTask:SearchTask) => {
    postUrl(process.env.REACT_APP_API_URL + "/api/searchTasks", searchTask, reduceInsertedSearchTask)
  };

  const deleteSearchTask = (deletedSearchTask:SearchTask) => {
    deleteUrl(process.env.REACT_APP_API_URL + "/api/searchTasks/" + deletedSearchTask.id, deletedSearchTask, reduceDeletedSearchTask)
  };

  const updateSearchTask = (updatedSearchTask:SearchTask) => {
    putUrl(process.env.REACT_APP_API_URL + "/api/searchTasks/" + updatedSearchTask.id, updatedSearchTask, reduceUpdatedSearchTask)
  };

  const handleShowAllUsers = () => {
    setShowAllUsers(isShowAllUsers === "true" ? "false" : "true");
  };

  const handleShowOnlyActive = () => {
    setShowOnlyActive(isShowOnlyActive === "true" ? "false" : "true");
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "login";
  };


  const addSearchTask = () => {
    setCurrentSearchTask(initialFormState);
    setAddModalOpen(true);
  };

  const editSearchTask = (searchTask:SearchTask) => {
    setCurrentSearchTask(searchTask);
    setEditModalOpen(true);
  };

  const showPostings = (searchTask:SearchTask) => {
    setCurrentSearchTask(searchTask);
    setPostingsModalOpen(true);
  };

  const showAllUsersButton = (
    <Button onClick={() => handleShowAllUsers()} className={isShowAllUsers === 'true' ? 'green' : ''} icon='address book' />
  );

  const showOnlyActiveButton = (
    <Button onClick={() => handleShowOnlyActive()} className={isShowOnlyActive === 'true' ? 'green' : ''} icon='check' />
  );

  const logoutButton = (
    <Button onClick={handleLogout} icon='sign out alternate' />
  );

  return (
    <Route>
      <Grid centered columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Button
              onClick={addSearchTask}
              primary
              icon="plus"
              content="Add search task" />
            <SearchTaskForm
              callBack={insertSearchTask}
              isModalOpen={isAddModalOpen}
              setModalOpen={setAddModalOpen}
              loading={loading}
              title="Add search task"
              currentSearchTask={initialFormState}
            />

            <div style={{float: 'right'}} >
              {isAdmin &&
                <Popup trigger={showAllUsersButton} content='Show search tasks for all users' />
              }
              <Popup trigger={showOnlyActiveButton} content='Show only active search tasks' />
              <Popup trigger={logoutButton} content='Logout' />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Header as='h2'>View search tasks</Header>
              {loading ? (
                "Loading..."
              ) : (
                <SearchTaskTable searchTasks={searchTasks} editRow={editSearchTask} deleteRow={deleteSearchTask} showPostings={showPostings} isAdmin={isAdmin} />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <SearchTaskForm
        callBack={updateSearchTask}
        isModalOpen={isEditModalOpen}
        setModalOpen={setEditModalOpen}
        loading={loading}
        title="Edit search task"
        currentSearchTask={currentSearchTask}
        user={user}
      />

      <Postings
        isModalOpen={isPostingsModalOpen}
        setModalOpen={setPostingsModalOpen}
        loading={loading}
        setLoading={setLoading}
        title="Found postings"
        currentSearchTask={currentSearchTask}
      />
    </Route>
  )
};

export default SearchTasks
