import React from 'react'
import {Route} from 'react-router-dom'
import {Grid, Segment} from "semantic-ui-react";
import SignupForm from "../forms/SignupForm";

const Signup = () => {


  return (
    <Route>
      <Grid centered columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <SignupForm/>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Route>
  )
};

export default Signup