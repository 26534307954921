import React from 'react'
import {Route} from 'react-router-dom'
import {Grid, Segment} from "semantic-ui-react";
import 'fomantic-ui-css/semantic.min.css';
import RequestPwdResetForm from "../forms/RequestPwdResetForm";

const RequestPwdReset = () => {

  return (
    <Route>
      <Grid centered columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <RequestPwdResetForm/>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Route>
  )
};

export default RequestPwdReset
