import React, {useState} from 'react'
//import {useMedia} from 'react-use';
import {Button, Icon, Table, Confirm, Label} from "semantic-ui-react";
import Moment from 'react-moment';
import helpers from '../helpers'

interface Props {
  searchTasks: Array<SearchTask>
  deleteRow: Function
  editRow: Function
  showPostings: Function
  isAdmin: boolean
}

const SearchTaskTable = (props:Props) => {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const deleteSearchTask = (searchTask:SearchTask) => {
    props.deleteRow(searchTask);
    setConfirmOpen(false);
  };

  return (
    <>
      {props.searchTasks.length > 0 ? (
        <>
          {props.searchTasks.map(searchTask => (
            <Table unstackable key={searchTask.id}>
              <Table.Body>
                <Table.Row className='table-row-header'>
                  <Table.Cell colSpan={2}>
                    {searchTask.description}
                    <div style={{float: 'right'}}>
                      <Button
                        onClick={() => {
                          props.showPostings(searchTask)
                        }}
                        icon='list'/>
                      <Button
                        onClick={() => {
                          props.editRow(searchTask)
                        }}
                        icon='edit'/>
                      <Button
                        onClick={() => setConfirmOpen(true)}
                        icon='trash alternate'/>
                      <Confirm
                        header='Delete search task'
                        open={confirmOpen}
                        onCancel={() => setConfirmOpen(false)}
                        onConfirm={() => deleteSearchTask(searchTask)}/>
                    </div>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    Website
                  </Table.Cell>
                  <Table.Cell className='breakword'>
                    <a href={searchTask.searchUrl} rel="noopener noreferrer" target="_blank">{searchTask.searchUrl}</a>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    Email address(es)
                  </Table.Cell>
                  <Table.Cell className='breakword'>
                    {searchTask.emailRecipientAddress}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    Status
                  </Table.Cell>
                  <Table.Cell className='breakword search-task-status'>
                    Last run status:
                     {searchTask.statusOfLastRun &&
                      <Label size='tiny'
                             color={searchTask.statusOfLastRun === 'SUCCESS' ? 'green' : 'red'}
                             content={searchTask.statusOfLastRun}/>
                    }
                    <br/>
                    Last success on:
                    {searchTask.lastSuccessfulRunTime &&
                      <Moment date={searchTask.lastSuccessfulRunTime} interval={0} format=' D.M.YYYY HH:mm'/>
                    }
                    <br/>
                    Last postings found on site:
                    {searchTask.lastFoundPostingsTime &&
                    <Moment date={searchTask.lastFoundPostingsTime} interval={0} format=' D.M.YYYY HH:mm'/>
                    }
                    <br/>
                    Last notifications found on:
                    {searchTask.lastFoundNewPostingsTime &&
                      <Moment date={searchTask.lastFoundNewPostingsTime} interval={0} format=' D.M.YYYY HH:mm'/>
                    }
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>
                    Frequency
                  </Table.Cell>
                  <Table.Cell className='breakword'>
                    {helpers.getFrequencyName(searchTask.frequency)}
                  </Table.Cell>
                </Table.Row>
                {(props.isAdmin && searchTask.user) &&
                <Table.Row>
                  <Table.Cell collapsing>
                    User
                  </Table.Cell>
                  <Table.Cell className='breakword'>
                    {searchTask.user.username}
                  </Table.Cell>
                </Table.Row>
                }
                <Table.Row>
                  <Table.Cell collapsing>
                    Active
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name='check' size='large'
                          color={searchTask.active ? 'green' : 'grey'} disabled={!searchTask.active}/>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          ))}
        </>

      ) : (

        <Table className='no-border'>
          <Table.Body><Table.Row><Table.Cell>You have no search tasks</Table.Cell></Table.Row></Table.Body>
        </Table>

      )}
    </>
  )
};

export default SearchTaskTable
