import React, { useState, FormEvent } from 'react'
import {Button, Input, Form, Header, Message} from "semantic-ui-react";
import {login} from "../components/FetchApi";
import {Link} from "react-router-dom";

const LoginForm = () => {
	const [ user, setUser ] = useState({username: '', password: ''});
	const [ isError, setError ] = useState(false);

	const handleInputChange = (event:FormEvent<HTMLInputElement>) => {
		const { name, value } = (event.target as HTMLInputElement);
		setUser({ ...user, [name]: value });
	};

	const handleResponse = (response:Response) => {
		if (response.status === 200) {
			window.location.href = "/";
		} else {
			setError(true);
		}
	};

	const handleSubmit = (event:FormEvent) => {
		event.preventDefault();
		if (user.username === '' || user.password === '') return;

		login(process.env.REACT_APP_API_URL + "/perform_login", user, handleResponse);
	};

	return (
		<>
			<Header as='h2' textAlign="center">Login</Header>
			<Form size="large" onSubmit={event => {
				handleSubmit(event);
			}} error={isError}>
				<Form.Field
					control={Input}
					label='Email'
					name="username"
					value={user.username}
					onChange={handleInputChange}
					required
				/>
				<Form.Field
					control={Input}
          type='password'
					label='Password'
					name="password"
					value={user.password}
					onChange={handleInputChange}
					required
				/>
				<Button primary icon='sign in alternate' content="Login" />
				<Link style={{ float: 'right' }} to="/request_reset_pwd">Forgot password?</Link>
				<Message
      		error
      		content='Login failed, please try again.' />
			</Form>
		</>
	)
};

export default LoginForm
