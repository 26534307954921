import React, {useState, useEffect, FormEvent} from 'react'
import {Button, Select, Input, Form, Modal, Checkbox, Accordion, Icon, CheckboxProps} from "semantic-ui-react";
import helpers from '../helpers'

export const initialFormState: SearchTask = {
  id: undefined,
  parserName: '',
  frequency: 'DAILY_5_MINS',
  description: '',
  searchUrl: '',
  emailRecipientAddress: '',
  descriptionRegex: '',
  displayPriceRegex: '',
  checkPriceChanged: true,
  allowAdminToViewDetails: false,
  notifyByEmail: true,
  notifyByPush: true,
  active: true,
  statusOfLastRun: undefined,
  lastFoundPostingsTime: undefined,
  lastFoundNewPostingsTime: undefined,
  lastSuccessfulRunTime: undefined
};

interface Props {
  currentSearchTask: SearchTask
  callBack: Function
  setModalOpen: Function
  isModalOpen: boolean
  loading: boolean
  title: string
  user?: User
}

const SearchTaskForm = (props: Props) => {

  const [searchTask, setSearchTask] = useState<SearchTask>(initialFormState);
  const [advancedOpen, setAdvancedOpen] = useState<boolean>(false);

  useEffect(
    () => {
      setSearchTask(props.currentSearchTask);
      if (!!searchTask.descriptionRegex || !!searchTask.displayPriceRegex) {
        setAdvancedOpen(true);
      }
    }, [props]
  );

  const resetForm = () => {
    setSearchTask(initialFormState);
  };

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const {name, value} = (event.target as HTMLInputElement);
    setSearchTask({...searchTask, [name]: value});
  };

  const handleSelectChange = (event: FormEvent<HTMLInputElement>, data: HTMLSelectElement) => {
    const {name, value} = data;
    setSearchTask({...searchTask, [name]: value});
  };

  const handleToggleChange = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const {name, checked} = data;
    setSearchTask({...searchTask, [name as string]: checked});
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!searchTask.parserName || !searchTask.description) return;

    props.callBack(searchTask);
    props.setModalOpen(false);
    resetForm();
  };

  const closeModal = () => {
    setAdvancedOpen(false);
    props.setModalOpen(false);
    resetForm();
  };

  return (
    <Modal
      open={props.isModalOpen}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={closeModal}
    >
      <Modal.Header as='h2' className={"ui"}>{props.title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form id="semanticui-issue-2554" size="large" onSubmit={event => {
            handleSubmit(event);
          }}>
            <Form.Field
              name="parserName"
              control={Select}
              label='Website'
              value={searchTask.parserName}
              options={helpers.parserNames}
              placeholder='Select website'
              onChange={handleSelectChange}
              required
            />
            <Form.Field
              name="frequency"
              control={Select}
              label='Frequency'
              value={searchTask.frequency}
              options={helpers.frequencyNames}
              placeholder='Select frequency'
              onChange={handleSelectChange}
              required
            />
            <Form.Field
              control={Input}
              label='Task Name'
              name="description"
              placeholder="Something to describe your tracking"
              value={searchTask.description}
              onChange={handleInputChange}
              required
            />
            <Form.Field
              control={Input}
              label='Search Url'
              name="searchUrl"
              placeholder="The full url of the search result page"
              value={searchTask.searchUrl}
              onChange={handleInputChange}
              required
            />
            <Form.Field
              control={Input}
              label='Email Recipient Address(es)'
              name="emailRecipientAddress"
              placeholder="Semicolon or comma separated list of email addresses"
              value={searchTask.emailRecipientAddress}
              onChange={handleInputChange}
            />
            <Form.Field>
              <Accordion>
                <Accordion.Title
                  active={advancedOpen}
                  onClick={() => setAdvancedOpen(!advancedOpen)}>
                  <Icon name='dropdown'/>
                  Advanced settings
                </Accordion.Title>
                <Accordion.Content active={advancedOpen}>
                  <Form.Field
                    control={Input}
                    label='Search Result Text Regex'
                    name="descriptionRegex"
                    placeholder="Regular expression matching the search result like: (?:16 GB|16GB).*(?:512 GB|512GB)"
                    value={searchTask.descriptionRegex || ''}
                    onChange={handleInputChange}
                  />
                  <Form.Field
                    control={Input}
                    label='Search Result Price Regex'
                    name="displayPriceRegex"
                    placeholder="Regular expression matching the search result price like: (?i)alv"
                    value={searchTask.displayPriceRegex || ''}
                    onChange={handleInputChange}
                  />
                </Accordion.Content>
              </Accordion>
            </Form.Field>
            <Form.Group inline>
              <Form.Field>
                <Checkbox toggle
                          name="checkPriceChanged"
                          label='Notify of price change'
                          onChange={handleToggleChange}
                          checked={searchTask.checkPriceChanged}/>
              </Form.Field>
              <Form.Field>
                <Checkbox toggle
                          name="active"
                          label='Is the search active'
                          onChange={handleToggleChange}
                          checked={searchTask.active}/>
              </Form.Field>
              <Form.Field>
                <Checkbox toggle
                          name="allowAdminToViewDetails"
                          label='Can support see details of this task'
                          onChange={handleToggleChange}
                          checked={searchTask.allowAdminToViewDetails}
                          disabled={props.user && !helpers.canViewDetails(props.user, searchTask)}/>
              </Form.Field>
              <Form.Field>
                <Checkbox toggle
                          name="notifyByEmail"
                          label='Notify by Email'
                          onChange={handleToggleChange}
                          checked={searchTask.notifyByEmail}/>
              </Form.Field>
              <Form.Field>
                <Checkbox toggle
                          name="notifyByPush"
                          label='Notify by Push'
                          onChange={handleToggleChange}
                          checked={searchTask.notifyByPush}/>
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button form="semanticui-issue-2554" primary loading={props.loading}
                disabled={props.loading}>{props.title}</Button>
        <Button onClick={() => closeModal()}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  )
};

export default SearchTaskForm
