
function getToken() {
  return localStorage.getItem('accessToken');
}

function setToken(token:string) {
  return localStorage.setItem('accessToken', token);
}

function isAuthorized(response:Response) {
  return response.status !== 400 && response.status !== 401 && response.status !== 403;
}

async function fetchUrl(url:string, callBack:Function) {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  });
  if (!isAuthorized(response)) {
    window.location.href = "login";
  }
  const json = await response.json();
  callBack(json);
}

async function syncFetchUrl(url:string) {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  });
  if (!isAuthorized(response)) {
    window.location.href = "login";
  }
  const json = await response.json();
  return json;
}

async function postUrl(url:string, data:Object, callBack:Function) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(data)
  });
  if (!isAuthorized(response)) {
    window.location.href = "login";
  }
  const json = await response.json();
  callBack(json);
}

async function putUrl(url:string, data:Object, callBack:Function) {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(data)
  });
  if (!isAuthorized(response)) {
    window.location.href = "login";
  }
  const json = await response.json();
  callBack(json);
}

async function deleteUrl(url:string, data:Object, callBack:Function) {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    }
  });
  if (!isAuthorized(response)) {
    window.location.href = "login";
  }
  const json = await response.json();
  callBack(json);
}

async function post(url:string, data:Object, callBack:Function) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  callBack(response);
}

async function login(url:string, data:Object, callBack:Function) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify(data)
  });
  const json = await response.json();
  if (response.status === 200) {
    setToken(json.token);
  }
  callBack(response);
}

async function get(url:string, callBack:Function) {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  });
  if (!isAuthorized(response)) {
    window.location.href = "login";
  } else {
    callBack(response);
  }
}

async function refreshToken(url:string) {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
  });
  if (response.status === 200) {
    const json = await response.json();
    setToken(json.token);
  }
}

export { fetchUrl, syncFetchUrl, postUrl, putUrl, deleteUrl, post, get, login, refreshToken };